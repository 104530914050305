import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MaterialUiMarkdown } from './materialUiMarkdown'
import { parseDateString } from '../utils/dateUtils'
import Content from './content'
import { getDescriptionFromExcerptAst } from '../utils/remarkUtils'

const NewsPost = ({ data }) => {
  const post = data.markdownRemark

  const options = { year: 'numeric', month: 'long', day: '2-digit' }
  const dateString = new Intl.DateTimeFormat('de-DE', options).format(
    parseDateString(post.frontmatter.date)
  )

  const description = post.noAutoDescription
    ? null
    : getDescriptionFromExcerptAst(post.excerptAst)

  return (
    <Content
      title={post.frontmatter.title}
      caption={dateString}
      description={description}
    >
      <MaterialUiMarkdown htmlAst={post.htmlAst} excludedTags={['h1']} />
    </Content>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      excerptAst
      frontmatter {
        title
        date
        noAutoDescription
      }
    }
  }
`

NewsPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
      }),
    }),
  }),
}

export default NewsPost
